import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";

const wrapper = css`
  width: 100%;
`

const boxAria = css`
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  ${mediaQuery[smartPhone]} {
    height: 138px;
    padding-bottom: 21px;
  }
`

const leftAriaL = css`
  width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  background-color: ${color.mainL};
  ${mediaQuery[smartPhone]} {
    width: 100px;
  }
`

const leftAriaD = css`
  width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  background-color: ${color.mainD};
  ${mediaQuery[smartPhone]} {
    width: 100px;
  }
`

const num = css`
  display: block;
  font-family: 'Noto Serif JP', serif;
  font-size: 64px;
  font-weight: bold;
  color: ${color.white};
  line-height: 92px;
  margin-bottom: 8px;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.subtitleSize};
    line-height: 34px;
  }
`

const title = css`
  display: block;
  font-size: 18px;
  line-height: 27px;
  color: ${color.white};
  ${mediaQuery[smartPhone]} {
    font-size: 10px;
    line-height: 150%;
    padding: 0 4px;
  }
`

const rightAria = css`
  display: flex;
  align-items: center;
  width: 862px; 
  padding-right: 40px;
  background-color: ${color.white};
  ${mediaQuery[smartPhone]} {
    width: 261px;
  }
`

const triangleL = css`
  width: 0;
  height: 0;
  border-left: 10px solid ${color.mainL};
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  padding-right: 75px;
  ${mediaQuery[smartPhone]} {
    padding-right: 8px;
  }
`

const triangleD = css`
  width: 0;
  height: 0;
  border-left: 10px solid ${color.mainD};
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  padding-right: 75px;
  ${mediaQuery[smartPhone]} {
    padding-right: 8px;
  }
`

const text = css`
  display: block;
  font-size: 18px;
  line-height: 27px;
  color: ${color.black};
  ${mediaQuery[smartPhone]} {
    font-size: 10px;
    line-height: 150%;
  }
`


export const OpeningFlowBlock = () => {
  return (
    <div css={wrapper}>
      <div css={boxAria}>
        <div css={leftAriaL}>
          <p css={num}>01</p>
          <p css={title}>開業地選定</p>
        </div>
        <div css={rightAria}>
          <div css={triangleL}></div>
          <p css={text}>
            先生のご希望の地域から開業地を選定します<br></br>
            多数の実績から将来性の予測を行い、立地の調査を致します<br></br>
            経営理念・診療方針に最適な物件のご提案が可能です
          </p>
        </div>
      </div>
      <div css={boxAria}>
        <div css={leftAriaD}>
          <p css={num}>02</p>
          <p css={title}>診療圏調査</p>
        </div>
        <div css={rightAria}>
          <div css={triangleD}></div>
          <p css={text}>
            選定した開業地から診療圏調査を致します<br></br>
            人口動向、推定患者数、受診率を把握し最新の情報を基にマーケティングを行います<br></br>
            机上の予測だけでなく実際に現地へ足を運び調査を行うことでより最適なご提案が可能です
          </p>
        </div>
      </div>
      <div css={boxAria}>
        <div css={leftAriaL}>
          <p css={num}>03</p>
          <p css={title}>事業計画策定</p>
        </div>
        <div css={rightAria}>
          <div css={triangleL}></div>
          <p css={text}>
            採算性を重視した収支の想定を行い、より安定的な医院経営にむけた支援を致します<br></br>
            資金計画書、損益計算書・キャッシュフローを作成し事業収支を的確に算定することで<br></br>
            最適な開業プランのご提案が可能です
          </p>
        </div>
      </div>
      <div css={boxAria}>
        <div css={leftAriaD}>
          <p css={num}>04</p>
          <p css={title}>開業資金調達</p>
        </div>
        <div css={rightAria}>
          <div css={triangleD}></div>
          <p css={text}>
            作成した事業計画書を基に金融機関との交渉をサポート致します<br></br>
            多数の融資実績より、金融機関への相談・申込・面談等のサポートも行います<br></br>
            金融機関から好条件を引き出すことで、無理のない返済計画の立案が可能です
          </p>
        </div>
      </div>
      <div css={boxAria}>
        <div css={leftAriaL}>
          <p css={num}>05</p>
          <p css={title}>建設設計</p>
        </div>
        <div css={rightAria}>
          <div css={triangleL}></div>
          <p css={text}>
            医院様に特化したノウハウを持った設計士をご紹介致します<br></br>
            患者様に好まれるレイアウトやスタッフの機能性を配慮することで最適な建築設計のご提案が可能です
          </p>
        </div>
      </div>
      <div css={boxAria}>
        <div css={leftAriaD}>
          <p css={num}>06</p>
          <p css={title}>医療機器導入のアドバイス</p>
        </div>
        <div css={rightAria}>
          <div css={triangleD}></div>
          <p css={text}>
            医療機器の選定と発注業者との交渉をサポート致します<br></br>
            医療機器保守業者との連携も行うためでアフターサービスを充実させることが可能です
          </p>
        </div>
      </div>
      <div css={boxAria}>
        <div css={leftAriaL}>
          <p css={num}>07</p>
          <p css={title}>広告・看板作成</p>
        </div>
        <div css={rightAria}>
          <div css={triangleL}></div>
          <p css={text}>
            効果的な求人や開業の広告・看板の作成をサポート致します<br></br>
            チラシ等の紙媒体の広告から、電子広告まで様々なツールを駆使することで幅広く周知が可能です
          </p>
        </div>
      </div>
      <div css={boxAria}>
        <div css={leftAriaD}>
          <p css={num}>08</p>
          <p css={title}>スタッフ採用</p>
        </div>
        <div css={rightAria}>
          <div css={triangleD}></div>
          <p css={text}>
            人員計画を策定し、スタッフの募集から採用までサポート致します<br></br>
            採用時には応募者の適性、相性を総合的に考慮しアドバイスを行います<br></br>
            採用後もシフト組み、労務管理も行うことで診療の円滑化が可能です
          </p>
        </div>
      </div>
      <div css={boxAria}>
        <div css={leftAriaL}>
          <p css={num}>09</p>
          <p css={title}>行政機関への各種届出</p>
        </div>
        <div css={rightAria}>
          <div css={triangleL}></div>
          <p css={text}>
            医院開設に必要な書類の作成・提出をサポート致します<br></br>
            保険医療機関指定申請や所得税青色申告承認申請届出など、<br></br>
            各種行政手続きを代行することで先生の負担軽減が可能です
          </p>
        </div>
      </div>
      <div css={boxAria}>
        <div css={leftAriaD}>
          <p css={num}>10</p>
          <p css={title}>薬局の開局</p>
        </div>
        <div css={rightAria}>
          <div css={triangleD}></div>
          <p css={text}>
            多数の実績から信頼できるパートナーをご紹介致します<br></br>
            開院に合わせて近隣の調剤薬局の誘致を実施することで最適なパートナー選定が可能です
          </p>
        </div>
      </div>
      <div css={boxAria}>
        <div css={leftAriaL}>
          <p css={num}>11</p>
          <p css={title}>開業直前</p>
        </div>
        <div css={rightAria}>
          <div css={triangleL}></div>
          <p css={text}>
            スタッフの研修、検査シミュレーション実施をサポート致します<br></br>
            余裕を持ったスケジューリングを行うことで先生もスタッフも不安なくオープンを迎えることができます
          </p>
        </div>
      </div>
    </div>
  );
};
