import React, { useState, useEffect } from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";
import { Layout } from "../components/Layout";
import { Head } from "../components/Head";
import { OpeningFlowBlock } from "../components/OpeningFlowBlock";
import home55 from '../images/home-5-5.png'
import openingSupport11 from '../images/opening-support-1-1.jpg'
import openingSupport11Phone from '../images/opening-support-1-1-phone.png'
import { AddContentsCard } from '../components/AddContentsCard.js'
const wrapper = css`
  width: 100%;
  background-color: ${color.whiteGray};
  margin: 0 auto;
`

const bgWhite = css`
  background-color: ${color.white};
  height: 134px;
  ${mediaQuery[smartPhone]} {
    height: 190px;
  }
`

const imageAria = css`
  height: 670px;
  background-image: url(${home55});
  background-repeat: repeat-x;
  text-align: center;
`

const titleAria = css`
  ${mediaQuery[smartPhone]} {
    margin-top: -100px;
  }
`

const mainTitle = css`
  font-family: 'Noto Serif JP', serif;
  font-size: 14px;
  font-weight: bold;
  color: #8F5E5E;
  line-height: 20px;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    line-height: 17px;
  }
`

const subTitle = css`
  display: block;
  padding: 24px 0;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.NomaltitleSize};
  color: #5F5450;
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
    line-height: 26px;
    padding: 12px 0 16px 0;
  }
`

const border = css`
  width: 80px;
  height: 2px;
  margin: 0 auto;
  background-color: ${color.gray};
  ${mediaQuery[smartPhone]} {
    width: 60px;
    height: 1px;
  }
`

const container = css`
  width: 90%;
  padding-bottom: 88px;
  margin: -465px auto 0 auto;
`

const triangle = css`
  width: 100%;
  height: 220px;
  background-image: url(${openingSupport11});
  background-color: ${color.white};
  background-size: cover;
  ${mediaQuery[smartPhone]} {
    background-image: url(${openingSupport11Phone});
    background-size: auto;
    height: 116px;
  }
`

const opening = css`
  padding: 60px 0 80px 0;
  background-color: ${color.white};
  text-align: center;
  ${mediaQuery[smartPhone]} {
    padding: 28px 0 96px 0;
  }
`

const openingText = css`
  display: block;
  width: 1134px;
  padding: 42px 0;
  margin: 0 auto;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.NomaltitleSize};
  line-height: 186%;
  letter-spacing: 1rem;
  border: 2px solid ${color.mainD};
  color: ${color.mainD};
  ${mediaQuery[smartPhone]} {
    display: block;
    max-width: 309px;
    padding: 13px 0;
    font-size: ${font.ButtonTextSize};
    border: 1px solid ${color.mainD};
  }
`

const AddContents = css`
  margin: 40px auto;
  width: 600px;
  ${mediaQuery[smartPhone]} {
    width:90%;
  }
`

export default function OpeningSupport({ data, location }) {
  const siteMetadata = data?.site?.siteMetadata
  const title = "開業支援";
  const [pathname, setPathname] = useState("");
  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  return (
    <Layout location={pathname}>
      <Head props={siteMetadata} />
      <div css={wrapper}>
        <div css={bgWhite}></div>
        <div css={imageAria}>
          <div css={titleAria}>
            <h3 css={mainTitle}>FLOW</h3>
            <p css={subTitle}>開業までの流れ</p>
            <div css={border}></div>
          </div>
        </div>
        <div css={container}>
          <OpeningFlowBlock />
        </div>
        <div css={triangle}></div>
        <div css={opening}>
          <p css={openingText}>開業</p>
        </div>
      </div>
      <div css={AddContents}>
        <AddContentsCard
          mainText1="新規開業・事業承継を"
          mainText2="お考えの方はこちら"
          subText="今後、奈良県でのご開業に興味のある方は、こちらから情報配信登録をしていただきますと、開業に関する情報などをメールにて優先的に配信させていただきます。是非ご活用ください。"
          link="https://forms.gle/A4kJfHRyKdqJuQhx6"
          btnText="開業サポート登録"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title,
        siteUrl,
        description
      }
    }
    allMicrocmsNews {
      nodes {
        daytime
        newsImage1 {
          url
        }
        newsImage2 {
          url
        }
        newsImage3 {
          url
        }
        newsImage4 {
          url
        }
        newsText
        newsTitle
        id
      }
    }
  }
`
